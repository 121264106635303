.login,
.image {
    min-height: 100vh
}

.bg-image {
    background-size: cover;
    background-position: center center;
    background : #4776E6;
    background-image: linear-gradient(to left, rgba(37, 92, 253, 0.85), rgba(59, 70, 236, 0.85), rgba(87, 64, 202, 0.85), rgba(108, 60, 187, 0.85), rgba(115, 0, 189, 0.85));
   
}