.scroll::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.scroll::-webkit-scrollbar-track {
  background: rgb(253, 253, 253); /* color of the tracking area */
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #6f64e7; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #ffffff; /* creates padding around scroll thumb */
}

.responsive-map-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.responsive-map-container iframe,
.responsive-map-container object,
.responsive-map-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1000px) {
  #menu-superior {
    display: none;
  }
  #mySidenav{
    display: block;
  }
  #btn-show-menu{
    display: block;

  }
}

@media screen and (min-width: 1000px) {
  
  #btn-show-menu{
    display: none;

  }
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
